<template>
  <div>
    <!-- 头部部分 -->
    <div class="titlebox">
      <div class="title">调货管理</div>
      <div>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input
              placeholder="输入商品型号搜索"
              prefix-icon="el-icon-search"
              v-model="limit.keyword"
            >
            </el-input>
          </el-col>
          <el-col :span="4"
            ><el-button class="addButton-class" @click="searchClick"
              >搜索</el-button
            ></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 表格部分 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      element-loading-text="拼命加载中"
      element-loading-background="#fff"
      border
    >
      <el-table-column
        prop="create_time"
        label="日期"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="no"
        label="单号"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column label="商品图片" align="center" width="100">
        <template scope="scope">
          <el-image :src="scope.row.goods_info.goods_url"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_info.bar_code"
        label="条形码"
        align="center"
      ></el-table-column>
      <el-table-column
        label="二维码"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
            <vue-qr v-if="scope.row.goods_info.qr_code" :margin="80"  :text="scope.row.goods_info.qr_code" :size="100"></vue-qr>
            <div v-else>暂无数据</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_info.goods_type"
        width="100"
        label="型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.goods_brand"
        label="品牌"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.goods_norms"
        label="规格"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.goods_class"
        label="类别"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.goods_color"
        label="颜色"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.price"
        label="单价"
        align="center"
      ></el-table-column>
      <el-table-column label="数量" width="70" align="center">
        <template>1</template>
      </el-table-column>
      <el-table-column
        prop="goods_info.price"
        label="金额"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.reward"
        label="奖励"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.goods_other"
        label="附带配件"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.zk"
        label="折扣"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_info.total"
        label="折扣金额"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reason"
        label="反馈信息"
        align="center"
      ></el-table-column>
      <el-table-column label="详情" align="center">
        <template slot-scope="scope">
          <el-button @click="delClick(scope.row.read)" type="text" size="small"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="table-limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageArray"
      :page-size="this.limit.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogTableVisible" width="500px">
      <template>
        <div class="item">
          <div>调出仓：</div>
          <div>{{info.receive}}</div>
        </div>
        <div class="item">
          <div>调入仓：</div>
          <div>{{info.send}}</div>
        </div>
        <div class="item">
          <div>调货人：</div>
          <div>{{info.username}}</div>
        </div>
        <div class="item">
          <div>调货数量：</div>
          <div>1件</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { cargo_list } from "@/api/mediate.js";
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  data() {
    return {
      pageArray:this.$store.state.pageArray,
      limit: {
        page: 1,
        page_size: 10,
        keyword: "",
      },
      total: 0,
      loading: true,
      currentPage: 1,
      tableData: [],
      dialogTableVisible: false,
      info:{
          receive:'',
          send:'',
          username:''
      }
    };
  },
  created() {
    // 获取首页数据
    this.getinfo();
  },
  methods: {
    //获取列表信息
    getinfo() {
      cargo_list(this.limit).then((res) => {
        let data = res.data.data;
        this.tableData = data;
        data.forEach((element) => {
          element.create_time = element.create_time.slice(0, 10);
        });
        console.log(this.tableData);
        this.total = res.data.total;
        this.loading = false;
      });
    },

    // 点击搜索
    searchClick(){
        this.limit.page = 1
        this.getinfo()
    },

    //分页
    handleSizeChange(val) {
      this.limit.page_size = val;
      this.loading = true;
      this.getinfo();
    },
    handleCurrentChange(val) {
      this.limit.page = val;
      this.loading = true;
      this.getinfo();
    },
    delClick(e){
        this.dialogTableVisible = true
        this.info.receive = e.receive
        this.info.send = e.send
        this.info.username = e.username
    }
  },
};
</script>
<style scoped>
.titlebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.titlebox .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.titlebox .addButton-class {
  background: #2290ff;
  color: #fff;
  border: none;
}
.chakan {
  color: #2290ff;
}
.item {
  width: 460px;
  height: 81px;
  background: #ffffff;
  border: 2px solid #f0f0f0;
  border-radius: 3px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 30px;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
}
</style>